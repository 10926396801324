import axios, { fetch, imgpost, post } from '@/api';
import { getCountryOptions } from '@/utils/geoLocation/utils/getCountryOptions';
import { getLegacyData } from '@/utils/geoLocation/utils/getLegacyData';
import { put } from './index';

const BASE_URL = process.env.VUE_APP_API_IM_URL;

const userApi = {
  UserSig: '/api/tls/genUserSig',
  StudentInfo: '/api/student/headmaster_student',
  saveMessage: '/api/message/saveMsg',
  messageList: '/api/message/history_msg',
  queryState: '/api/user/query_state',
  sendEmail: '/api/subscribe/task/send_email',
  taskList: '/api/task/query_task_num',
  taskTableList: '/api/task/run_tasks/search',
  createGroup: '/api/group/create',
  queryFormType: '/api/follow_record/query_type',
  submitRecord: '/api/follow_record/submit',
  unSubscribeOnWinback: '/api/student/cancel/subscription',
  followRecord: '/api/follow_record/search',
  firstLesson: '/api/student/first_lesson_date',
  confirmFirstLesson: '/api/task/confirm/first_date',
  queryStudentDetail: '/api/student/query_msg',
  afreshSchedule: '/api/task/afresh_schedule',
  Calendar: '/api/task/group/calendar',
  playbackCourse: '/api/student/schedule/playback_videos',
  CourseSchedule: '/api/student/course_schedule',
  queryTimezones: '/api/student/timezones/ddl',
  changeClassAdmin: '/api/task/change_classAdmin',
  queryStudentByGroup: '/api/student/query_msg/by_group',
  finishedTaskList: '/api/task/already_done/group',
  finishedTableList: '/api/task/history_tasks/search',
  initOptions: '/api/task/options',
  copyMeTaskList: '/api/task/carbon_copy/group',
  feedBackDetail: '/api/task/process/detail',
  completeTaskDetail: '/api/task/complete',
  rejectTaskDetail: '/api/task/reject',
  addCommentsDetail: '/api/task/comments',
  copyMeTableList: '/api/task/carbon_copy/search',
  copyTaskDetail: '/api/task/carbon-copy',
  turnTaskDetail: '/api/task/turn',
  batchDownload: '/api/wechat/share/batch_download',
  sendPosterGroup: '/api/message/send_learning_report',
  feiShuLogin: '/public/auth/v2/feishu/login',
  feiShuBind: '/public/auth/v2/feishu/bind',
  StudentInfoPage: '/api/student/headmaster_student_page',
  completeMakeUp: '/api/task/class_admin/complete',
  getUploadOssToken: '/api/admin/file/sts/public',
  getCalendarTable: '/api/student/calendar',
  courseHomeWork: '/api/student/course',
  submitHomeWork: '/api/student/list/current',
  historyHomeWork: '/api/student/list/history',
  studentCu: '/api/student/cu',
  timezones: '/public/ref/timezones/ddl',
  countries: '/public/ref/countries/zh/ddl',
  currency: '/public/ref/currency/ddl',
  editStudent: '/api/student',
  searchParams: '/api/student/learning_report/search_params',
  assistant: '/api/student/assistant/ddl',
  classAdmin: '/api/student/class_admin/ddl',
  consultant: '/api/student/consultant/ddl',
  courseScheduleInfo: '/api/student/course_schedule/search',
  initFormResign: '/api/task/form',
  initAssignees: '/api/task/form/assignees',
  createTakeOverForm: '/api/task/start_process',
  createMeTableList: '/api/task/create_self/search',
  createMeTaskList: '/api/task/created/group',
  learningReport: '/api/student/learning_report/insert',
  learningReportSearch: '/api/student/learning_report/search',
  courseStructure: '/api/student/course_structure/search',
  orderSearch: '/api/student/order/search',
  checkPromote: '/api/task/check_promote',
  orderInfo: '/api/student/order',
  admissionProfile: '/public/student/admission_profile/findById',
  downPdf: '/api/student/order',
  cancelOrder: '/api/student/order/cancel',
  sendInvoice: '/api/student/order',
  logistics: '/api/student/order',
  checkLogistics: '/api/student/order/check/logistics',
  orderCount: '/api/student/order/count',
  questionDetail: '/api/student/question/detail',
  studentShareForm: '/api/task/course_unit/shared',
  playFlollowVideo: '/zoom/phone/record/play',
  queryClass: '/api/student/fromClass',
  querySchedule: '/api/student/course_schedule/search/1',
  updateFirstData: '/api/task/update/first_date',
  wxAccountTeacher: '/api/account/search',
  leadSourceSearch: '/api/dict/lead_source/search',
  normalSource: '/api/crm/track/batch/inner/normal',
  expandSubjectSource: '/api/crm/track/inner/expand-subject',
  claimPermission: '/api/crm/track/',
  DollSource: '/api/crm/track/inner/multi-child',
  searchMyClue: '/api/crm/track/inner/search',
  searchOwnerMyClue: '/api/crm/track/inner/query_tack',
  SendEmail: '/api/notification/email',
  EmailTempList: '/api/notification/email/template/search',
  getEmailSelItem: '/api/notification/email/option',
  searchMyClueNum: '/api/crm/track/inner/search/count',
  selClassAdmin: '/api/account/sub/find',
  DetailHistory: '/api/message/search/history_msg',
  getGroupMember: '/api/group/query_group_member',
  getTreeList: '/api/dept/tree_list',
  getTreeAccount: '/api/dept/search/account',
  getStudentTaskList: '/api/task/query_task_list/condition',
  cacheFollowRecord: '/api/follow_record/cache',
  getCacheFollowRecord: '/api/follow_record/find/cache',
  getHeadmasterList: '/api/account/search/condition',
  searchTeacherName: '/api/teacher/work_flow/search',
  getRecordStudent: '/api/student/query',
  getCourseReport: '/api/student/study_report',
  queryTelEmail: '/api/student/look/sensitive',
  updateSelfTel: '/api/student/update/sensitive',
  queryKnowledgeSummery: '/api/student/knowledge_summery/find',
  queryTakeOverStudent: '/api/student/headmaster_student_page/search',
  queryUnassignedStudent: '/api/student/headmaster_student/total',
  submitTakeOverStudent: '/api/task/student/take_over/create',
  queryStudentInfoData: '/api/student/student_pdf',
  queryClassResources: '/api/student/teaching_materials',
  queryStudentListNew: '/api/student_profile/search/page',
  setStudentColumns: '/api/account/user_setting/save',
  queryStudentColumns: '/api/account/user_setting/find',
  queryScreenOption: '/api/config/options',
  queryStudentDetailHead: '/api/student/head',
  queryStudentDetailData: '/api/student/base',
  queryStudentClassHour: '/api/student/courseUnit',
  queryStudentService: '/api/student/service',
  queryStudentSituation: '/api/student/profile',
  getAppointment: '/api/crm/track/classAdmin/inviteCourseUlr',
  getTaskTypeArr: '/api/task/taskNames',
  getTaskStatistics: '/api/task/statistics',
  uploadDataForm: '/api/upload',
  queryUploadData: '/api/upload/record/page',
  getLearnerHierarchy: '/api/student/rank',
  getStatistics: '/api/account/student_rank/statistics',
  getPerformanceTeacher: '/api/account/performance',
  queryTeacherCalendar: '/api/admin/teacher/calendar',
  getTeacherSchedule: '/api/admin/teacher/completed/schedule',
  getSubjectCourse: '/api/admin/scheduling/course/search',
  querySpecifiedClass: '/api/student/formClass/search/one',
  createdSpecifiedClass: '/api/student/schedule/form_class',
  getCourseScheduleList: '/api/admin/student/v2/course_schedule/student/search/',
  queryStudentClassHourNew: '/api/student/course_unit',
  submitCourseSchedule: '/api/student/course_unit/submit_review',
  queryClassHourNum: '/api/student/course_unit/transaction',
  queryClassHourAdjust: '/api/student/course_unit/review/search',
  submitClassHourAdjust: '/api/student/course_unit/review',
  queryRefundClassHour: '/api/student/course_unit/cu_root',
  submitRefundClassHour: '/api/student/course_unit/refund',
  finishLoseRefund: '/api/student/course_unit/complete/loss_refund',
  queryClassFlow: '/api/student/course_unit/transactions',
  queryChangeReason: '/api/dict/search/unique',
  queryHomeWorkList: '/api/student/homework/search',
  queryStudyReport: '/api/student/study_report/list',
  OrganizationData: '/api/account/order_track/statistics',
  OrganizationTableData: '/api/account/order_track/statistics/sub/list',
  peerTableData: '/api/account/order_track/statistics/same_level/list',
  memberTableData: '/api/account/order_track/statistics/member',
  enrollmentTime: '/api/task/course_unit/shared/reminder',
  profileStatistics: '/api/student/profile_statistics',
  queryStudentHomeWorkList: '/api/student/homework/search/assign_stu',
  queryDeductionClass: '/api/student/course_unit/deduct',
  queryCompensateClass: '/api/student/course_unit/compensate',
  submitPayStatus: '/api/review/submit',
  queryStudentStatusApproval: '/api/review/search/page',
  submitStatusApproval: '/api/review/complete',
  queryCreatorList: '/api/dept/search/account/list',
  queryOrderList: '/api/account/order/search/page',
  studentReminderActive: '/api/task/student_active/reminder',
  cancelApprove: '/api/student/course_unit/cancel',
  querySendNum: '/api/ent_weichat/group/send/detail',
  uploadGroupFile: '/api/ent_weichat/uploadFile',
  sendGroupApiNew: '/api/ent_weichat/sendGroup',
  sendGroupApi: '/api/ent_weichat/group/send',
  queryClassNameNew: '/api/student/schedule/fromClass',
  queryClassHour: '/api/admin/scheduling/distinction/substitute',
  queryMathTeacher: '/api/task/match/teacher',
  updateClassTeacher: '/api/admin/scheduling/change/teacher',
  createClassOrder: '/api/admin/teacher/order/create',
  queryShareLessons: '/api/student/share_cu/search',
  queryStudentOrderStatus: '/api/task/review/search',
  queryStatisticsNum: '/api/review/statistics',
  modifyName: '/api/student/modify_name',
  issuingManagement: '/api/admin/teacher/order/search',
  cancelIssuing: '/api/admin/teacher/order/cancel',
  exchangeRate: '/api/student/order/exchange_rate',
  queryConclusion: '/api/student/study/conclusion',
  queryCourseType: '/api/config/course_structure/search',
  queryCourseName: '/api/admin/scheduling/course/search',
  queryCourseLevelList: '/api/config/course/level',
  queryPerformanceData: '/api/student/study/performance',
  querySchedulingList: '/api/admin/scheduling/course/search',
  createStudyPlan: '/api/student/study/create',
  queryStudyPath: '/api/config/structure/child/search',
  querySectionNum: '/api/student/study/section/count',
  queryPlanHistory: '/api/student/study/search',
  queryHomeWorkSendNum: '/api/ent_weichat/group/send/detail/homework',
};

export function getUserSig(parameter) {
  return fetch(BASE_URL, userApi.UserSig, parameter);
}
export function getStudentInfo(parameter) {
  return fetch(BASE_URL, userApi.StudentInfo, parameter);
}
export function getStudentInfoPage(parameter) {
  return fetch(BASE_URL, userApi.StudentInfoPage, parameter);
}
export function saveHistoryMsg(parameter) {
  return post(BASE_URL, userApi.saveMessage, parameter);
}
export function historyMessageList(parameter) {
  return post(BASE_URL, userApi.messageList, parameter);
}
// 查询用户IM是否在线
export function queryState(parameter) {
  return fetch(BASE_URL, userApi.queryState, parameter);
}
// 推送邮件消息
export function sendEmail(parameter) {
  return fetch(BASE_URL, userApi.sendEmail, parameter);
}

// 主页我的待办列表接口
export function queryTaskList(parameter) {
  return fetch(BASE_URL, userApi.taskList, parameter);
}
// 主页我的待办表格接口
export function getTaskTableList(parameter) {
  return post(BASE_URL, userApi.taskTableList, parameter);
}
// 主页创建群
export function createGroup(parameter) {
  return post(BASE_URL, userApi.createGroup, parameter);
}
// 查询表单里的类型
export function queryFormType(parameter) {
  return fetch(BASE_URL, userApi.queryFormType, parameter);
}
// 提交跟进记录表单
export function submitRecord(parameter) {
  return post(BASE_URL, userApi.submitRecord, parameter);
}

// 取消订阅
export function unSubscribeOnWinback(id) {
  return put(BASE_URL, userApi.unSubscribeOnWinback + `/${id}`);
}
// 查询跟进记录列表

export function getFollowRecord(parameter) {
  return fetch(BASE_URL, userApi.followRecord, parameter);
}

// 查询学生是否有首课时间

export function getFirstLesson(parameter) {
  return post(BASE_URL, userApi.firstLesson, parameter);
}
// 确认首课时间
export function confirmFirstLesson(parameter) {
  return post(BASE_URL, userApi.confirmFirstLesson, parameter);
}
// 查询班主任下的指定学生的详情
export function queryStudentDetail(parameter) {
  return fetch(BASE_URL, userApi.queryStudentDetail, parameter);
}
// 重新确认首课时间
export function afreshSchedule(parameter) {
  return fetch(BASE_URL, userApi.afreshSchedule, parameter);
}
// 查询日历标记点

export function queryCalendar(parameter) {
  return fetch('http://172.16.4.98:8110', userApi.Calendar, parameter);
}
// 查询课节回放
export function playbackCourse(parameter) {
  return fetch(BASE_URL, userApi.playbackCourse, parameter);
}
// 根据课节Id查询课节信息
export function queryCourseSchedule(parameter) {
  return fetch(
    BASE_URL,
    `${userApi.CourseSchedule}/${parameter.uuid}${parameter.studentId ? `?studentId=${parameter.studentId}` : ''}`,
  );
}
// 查询时区列表
export function queryTimezones(parameter) {
  return fetch(BASE_URL, userApi.queryTimezones, parameter);
}
// 批量交接入群
export function changeClassAdmin(parameter) {
  return post(BASE_URL, userApi.changeClassAdmin, parameter);
}
// 聊天页查询学生信息
export function queryStudentByGroup(parameter) {
  return fetch(BASE_URL, userApi.queryStudentByGroup, parameter);
}
// 查询已办任务列表分组
export function finishedTaskList(parameter) {
  return fetch(BASE_URL, userApi.finishedTaskList, parameter);
}
// 查询已办任务列表表格数据
export function getFinishedTableList(parameter) {
  return post(BASE_URL, userApi.finishedTableList, parameter);
}

// 查询表格里枚举任务类型
export function initOptions(parameter) {
  return fetch(BASE_URL, userApi.initOptions, parameter);
}
// 查询抄送我得任务分组
export function copyMeTaskList(parameter) {
  return fetch(BASE_URL, userApi.copyMeTaskList, parameter);
}
// 获取抽屉里反馈详情
export function feedBackDetail(parameter) {
  return fetch(BASE_URL, userApi.feedBackDetail, parameter);
}
// 详情里面得完成
export function completeTaskDetail(parameter, taskId, processInstanceId) {
  return post(BASE_URL, `${userApi.completeTaskDetail}/${taskId}/${processInstanceId}`, parameter);
}
export function completeTaskDetail3(parameter) {
  const params = {
    message: parameter.message,
    userId: parameter.userId,
    nowTaskType: parameter.nowTaskType,
    taskOverIsValidate: parameter.taskOverIsValidate,
  };
  if (parameter.freeze) {
    params.freeze = parameter.freeze;
  }
  if (parameter.adjustScheduleProcess) {
    params.adjustScheduleProcess = parameter.adjustScheduleProcess;
  }

  return post(BASE_URL, `${userApi.completeTaskDetail}/${parameter.taskId}/${parameter.processInstanceId}`, params);
}

export function completeTaskDetail2(parameter) {
  return post(BASE_URL, `${userApi.completeTaskDetail}/${parameter.taskId}/${parameter.processInstanceId}`, {
    message: parameter.message,
    userId: parameter.userId,
    orderId: parameter.orderId,
    nowTaskType: parameter.nowTaskType,
    taskOverIsValidate: parameter.taskOverIsValidate,
  });
}
// 班级离职里面的交接班完成
export function completeTaskDetail1(parameter, createNewProcess) {
  return post(BASE_URL, `${userApi.completeTaskDetail}/${parameter.taskId}/${parameter.processInstanceId}`, {
    message: parameter.message,
    userId: parameter.userId,
    createNewProcess,
    taskType: 'TASK_CHANGE_TEACHER_VISIT',
    nextProcessDto: parameter.nextProcessDto,
    nowTaskType: parameter.nowTaskType,
    taskOverIsValidate: parameter.taskOverIsValidate,
  });
}
// 详情里面得拒绝
export function rejectTaskDetail(parameter) {
  return post(BASE_URL, `${userApi.rejectTaskDetail}/${parameter.processInstanceId}`, {
    reason: parameter.reason,
    userId: parameter.userId,
    taskId: parameter.taskId,
  });
}
// 详情里添加任务评论
export function addCommentsDetail(parameter) {
  return post(BASE_URL, `${userApi.addCommentsDetail}/${parameter.taskId}`, {
    message: parameter.message,
    createdBy: parameter.createdBy,
  });
}
// 查询表格数据里抄送列表
export function copyMeTableList(parameter) {
  return post(BASE_URL, userApi.copyMeTableList, parameter);
}
// 详情里抄送任务
export function copyTaskDetail(parameter) {
  return post(BASE_URL, `${userApi.copyTaskDetail}/${parameter.taskId}`, {
    req: {
      createdBy: parameter.createdBy,
      message: parameter.message,
    },
    userIds: parameter.userIds,
  });
}
// 详情里转交任务
export function turnTaskDetail(parameter) {
  return post(BASE_URL, userApi.turnTaskDetail, {
    taskId: parameter.taskId,
    turnAssigneeId: parameter.turnAssigneeId,
    message: parameter.message,
    userId: parameter.userId,
  });
}
// 批量下载海报
export function batchDownload(parameter, config) {
  return axios.post(BASE_URL + userApi.batchDownload, parameter, config);
}
// 发送海报到群里
export function sendPosterGroup(parameter) {
  return post(BASE_URL, userApi.sendPosterGroup, parameter);
}
// 飞书登录
export function feiShuLogin(parameter) {
  return fetch(BASE_URL, userApi.feiShuLogin, parameter);
}
// 飞书绑定
export function feiShuBind(parameter) {
  return post(BASE_URL, userApi.feiShuBind, parameter);
}
// 新生补升确认完成
export function completeMakeUp(parameter) {
  return fetch(BASE_URL, userApi.completeMakeUp, parameter);
}
// 获取上传oss的权限
export function getUploadOssToken(parameter) {
  return fetch(BASE_URL, userApi.getUploadOssToken, parameter);
}
// 获取课表周视图
export function getCalendarTable(parameter) {
  return post(BASE_URL, userApi.getCalendarTable, parameter);
}
// 查询课后作业预览的
export function courseHomeWork(parameter) {
  return fetch(BASE_URL, userApi.courseHomeWork, parameter);
}
// 获取学生已提交的作业
export function submitHomeWork(parameter) {
  return fetch(BASE_URL, userApi.submitHomeWork, parameter);
}
// 获取历史报告
export function historyHomeWork(parameter) {
  return fetch(BASE_URL, userApi.historyHomeWork, parameter);
}
// 学生课时信息
export function studentClassTime(parameter) {
  return fetch(BASE_URL, `${userApi.studentCu}/${parameter.studentId}`);
}
// 时区
export function timezones(parameter) {
  return fetch(BASE_URL, `${userApi.timezones}`);
}
// 城市
/**
 * @deprecated 请通过 geoLoction 统一获取国家
 */
export const countries = getLegacyData;

// 币种
export function currency(parameter) {
  return fetch(BASE_URL, `${userApi.currency}`);
}
// 助教
export function assistant(parameter) {
  return fetch(BASE_URL, `${userApi.assistant}`);
}
// 班主任
export function classAdmin(parameter) {
  return fetch(BASE_URL, `${userApi.classAdmin}`);
}
// 课程顾问
export function consultant(parameter) {
  return fetch(BASE_URL, `${userApi.consultant}`);
}
// 编辑学生
export function editStudent(parameter) {
  return post(BASE_URL, `${userApi.editStudent}/${parameter.uuid}`, parameter.data);
}
// 报告证书
export function searchParams(parameter) {
  return post(BASE_URL, userApi.searchParams, parameter);
}
// 查询排课信息
export function courseScheduleInfo(parameter) {
  return fetch(BASE_URL, userApi.courseScheduleInfo, parameter);
}
// 初始化设置里面离职表单信息
export function initFormResign(parameter) {
  return fetch(BASE_URL, `${userApi.initFormResign}/${parameter.type}`);
}
// 获取表单处理人的接口
export function initAssignees(parameter) {
  return post(BASE_URL, userApi.initAssignees, parameter);
}
// 设置页面提交离职表单
export function createTakeOverForm(parameter) {
  return post(BASE_URL, userApi.createTakeOverForm, parameter);
}
// 获取任务流里面我创建的列表
export function createMeTableList(parameter) {
  return post(BASE_URL, userApi.createMeTableList, parameter);
}
// 查询我创建的任务分组
export function createMeTaskList(parameter) {
  return fetch(BASE_URL, userApi.createMeTaskList, parameter);
}
// 订单详情
export function orderInfo(parameter) {
  return fetch(BASE_URL, `${userApi.orderInfo}/${parameter.orderId}`);
}
// 获取入学档案信息
export function getAdmissionProfile(orderId) {
  return fetch(BASE_URL, `${userApi.admissionProfile}/${orderId}`);
}
// 下载PDF
export function downPdf(parameter) {
  return fetch(BASE_URL, `${userApi.downPdf}/${parameter.uuid}/pdf`);
}
// 取消订单
export function cancelOrder(parameter) {
  return fetch(BASE_URL, `${userApi.cancelOrder}/${parameter.uuid}`);
}
// 发送invoice
export function sendInvoice(parameter) {
  return fetch(BASE_URL, `${userApi.sendInvoice}/${parameter.uuid}/invoice/send`);
}
// 订单数量
export function orderCount(parameter) {
  return fetch(BASE_URL, `${userApi.orderCount}/${parameter.uuid}`);
}
// 查询作业解析
export function questionDetail(parameter) {
  return fetch(BASE_URL, `${userApi.questionDetail}/${parameter.uuid}`);
}
// 判断是否去补升
export function checkPromote(parameter) {
  return post(BASE_URL, userApi.checkPromote, parameter);
}
// 获取报告证书、结业证书
export function learningReport(parameter) {
  return post(BASE_URL, userApi.learningReport, parameter);
}
// 课程搜索
export function courseStructure(parameter) {
  return post(BASE_URL, userApi.courseStructure, parameter);
}
// 搜索报告证书、结业证书
export function learningReportSearch(parameter) {
  return post(BASE_URL, `${userApi.learningReportSearch}/${parameter.num}`, parameter.data);
}
// 订单搜索
export function orderSearch(parameter) {
  return post(BASE_URL, `${userApi.orderSearch}/${parameter.num}`, parameter.data);
}
// 修改寄送
export function logistics(parameter) {
  return post(BASE_URL, `${userApi.logistics}/${parameter.uuid}/logistics`, parameter.data);
}
// 检查是否需要填写寄送信息
export function checkLogistics(parameter) {
  return post(BASE_URL, `${userApi.checkLogistics}`, parameter);
}
// 发起审批的提交表单
export function studentShareForm(parameter) {
  return post(BASE_URL, userApi.studentShareForm, parameter);
}
// 跟进记录语音播放
export function playFlollowVideo(parameter) {
  return fetch(BASE_URL, userApi.playFlollowVideo, parameter);
}
// 查询班级
export function queryClass(parameter) {
  return fetch(BASE_URL, userApi.queryClass, parameter);
}
// 查询班级下的课节
export function querySchedule(parameter) {
  return post(BASE_URL, userApi.querySchedule, parameter);
}
// 查询首课课节-新
export function queryScheduleNew(parameter) {
  return fetch(
    BASE_URL,
    `/api/student/student/${parameter.studentId}/standard-schedule?subject=${parameter.subject}&num=5`,
  );
}
// 更新首课时间
export function updateFirstData(parameter) {
  return post(BASE_URL, userApi.updateFirstData, parameter);
}
// 查询微信老师
export function wxAccountTeacher(parameter) {
  return fetch(BASE_URL, userApi.wxAccountTeacher, parameter);
}
// 查询线索来源
export function leadSourceSearch(parameter) {
  return fetch(BASE_URL, userApi.leadSourceSearch, parameter);
}
// 普通线索来源
export function normalSource(parameter) {
  return post(BASE_URL, userApi.normalSource, parameter);
}
// 学员拓科线索来源
export function expandSubjectSource(parameter) {
  return post(BASE_URL, userApi.expandSubjectSource, parameter);
}
// 领取线索
export function claimPermission(trackId) {
  return post(BASE_URL, `${userApi.claimPermission}${trackId}/claim/permission`);
}
// 一家多娃
export function DollSource(parameter) {
  return post(BASE_URL, userApi.DollSource, parameter);
}
// 查询线索
export function searchMyClue(parameter) {
  return post(BASE_URL, userApi.searchMyClue, parameter);
}
// 查询线索数量
export function searchMyClueNum(parameter) {
  return fetch(BASE_URL, userApi.searchMyClueNum, parameter);
}
// 查询线索,对应回填到表单
export function searchOwnerMyClue(parameter) {
  return fetch(BASE_URL, userApi.searchOwnerMyClue, parameter);
}
// 发送邮件
export function SendEmail(parameter) {
  return post(BASE_URL, userApi.SendEmail, parameter);
}
// 获取邮件模板列表
export function getEmailTempList(parameter) {
  return post(BASE_URL, userApi.EmailTempList, parameter);
}
// 获取邮件模板下来选择
export function getEmailSelItem(parameter) {
  return fetch(BASE_URL, userApi.getEmailSelItem, parameter);
}
// 获取邮件模板班主任列表
export function selClassAdmin(parameter) {
  return fetch(BASE_URL, userApi.selClassAdmin, parameter);
}
// 获取详情里查询历史记录消息
export function getDetailHistory(parameter) {
  return post(BASE_URL, userApi.DetailHistory, parameter);
}
// 获取详情里的群成员
export function getGroupMember(parameter) {
  return fetch(BASE_URL, userApi.getGroupMember, parameter);
}
// 获取我的组织里面部门树
export function getTreeList(parameter) {
  return fetch(BASE_URL, userApi.getTreeList, parameter);
}
// 获取组织里的账号
export function getTreeAccount(parameter) {
  return post(BASE_URL, userApi.getTreeAccount, parameter);
}
// 获取学生里面的待办任务列表
export function getStudentTaskList(parameter) {
  return post(BASE_URL, userApi.getStudentTaskList, parameter);
}
// 保存跟进记录草稿
export function cacheFollowRecord(parameter) {
  return post(BASE_URL, userApi.cacheFollowRecord, parameter);
}
// 获取跟进记录保存的草稿
export function getCacheFollowRecord(parameter) {
  return fetch(BASE_URL, userApi.getCacheFollowRecord, parameter);
}
// 获取班主任列表
export function getHeadmasterList(parameter) {
  return post(BASE_URL, userApi.getHeadmasterList, parameter);
}
// 查询授课老师
export function searchTeacherName(parameter) {
  return fetch(BASE_URL, userApi.searchTeacherName, parameter);
}
// 查询跟进记录里面的学生
export function getRecordStudent(parameter) {
  return fetch(BASE_URL, userApi.getRecordStudent, parameter);
}
// 查询课表里面的学习报告
export function getCourseReport(parameter) {
  return fetch(BASE_URL, userApi.getCourseReport, parameter);
}
// 查看手机号码邮箱
export function queryTelEmail(parameter) {
  return fetch(BASE_URL, userApi.queryTelEmail, parameter);
}
// 修改个人信息手机号码,邮箱
export function updateSelfTel(parameter) {
  return post(BASE_URL, userApi.updateSelfTel, parameter);
}
// 查询学习报告课节配置的文本接口
export function queryKnowledgeSummery(parameter) {
  return fetch(BASE_URL, userApi.queryKnowledgeSummery, parameter);
}
// 查询离职班主任下的所有学生
export function queryTakeOverStudent(parameter) {
  return post(BASE_URL, userApi.queryTakeOverStudent, parameter);
}
// 查询离职班主任未分配的学生总人数
export function queryUnassignedStudent(parameter) {
  return fetch(BASE_URL, userApi.queryUnassignedStudent, parameter);
}
// 离职班主任交接学员
export function submitTakeOverStudent(parameter) {
  return post(BASE_URL, userApi.submitTakeOverStudent, parameter);
}

// 离职班主任学生交接批量上传
export const uploadTakeOverStudent = () => {};

// 获取所有学生资料
export function queryStudentInfoData(parameter) {
  return fetch(BASE_URL, userApi.queryStudentInfoData, parameter);
}
// 获取课节资源
export function queryClassResources(parameter) {
  return fetch(BASE_URL, userApi.queryClassResources, parameter);
}
// 获取学生页面列表
export function queryStudentListNew(parameter) {
  return post(BASE_URL, userApi.queryStudentListNew, parameter);
}
// 学生页面自定义表头表格
export function setStudentColumns(parameter) {
  return post(BASE_URL, userApi.setStudentColumns, parameter);
}
// 获取学生自定义的表格列跟头
export function queryStudentColumns(parameter) {
  return fetch(BASE_URL, userApi.queryStudentColumns, parameter);
}
// 获取学生自定义的筛选项目
export async function queryScreenOption(parameter) {
  const [res, countries] = await Promise.allSettled([
    fetch(BASE_URL, userApi.queryScreenOption, parameter),
    getCountryOptions(),
  ]);
  if (countries.status === 'fulfilled' && res.status === 'fulfilled') {
    res.value.data.content.country = countries.value;
  }
  return res.value;
}
// 获取学生顶部详情
export function queryStudentDetailHead(parameter) {
  return fetch(BASE_URL, userApi.queryStudentDetailHead, parameter);
}
// 获取学生详情资料板块
export function queryStudentDetailData(parameter) {
  return fetch(BASE_URL, userApi.queryStudentDetailData, parameter);
}
// 获取学生详情课时板块
export function queryStudentClassHour(parameter) {
  return fetch(BASE_URL, userApi.queryStudentClassHour, parameter);
}
// 获取学生详情服务板块
export function queryStudentService(parameter) {
  return fetch(BASE_URL, userApi.queryStudentService, parameter);
}
// 获取学生详情学情板块
export function queryStudentSituation(parameter) {
  return fetch(BASE_URL, userApi.queryStudentSituation, parameter);
}
// 获取约课链接
export function getAppointment(parameter) {
  return fetch(BASE_URL, userApi.getAppointment, parameter);
}
// 获取下拉的任务类型
export function getTaskTypeArr(parameter) {
  return fetch(BASE_URL, userApi.getTaskTypeArr, parameter);
}
// 获取待办里面得任务数量

export function getTaskStatistics(parameter) {
  return post(BASE_URL, userApi.getTaskStatistics, parameter);
}
// 上传数据文件
export function uploadDataForm(parameter) {
  return imgpost(`${BASE_URL}${userApi.uploadDataForm}`, parameter, 'blob');
}
// 查询学生上传数据列表接口
export function queryUploadData(parameter) {
  return post(BASE_URL, userApi.queryUploadData, parameter);
}
// 获取学生详情里学员分层
export function getLearnerHierarchy(parameter) {
  return fetch(BASE_URL, userApi.getLearnerHierarchy, parameter);
}
// 首页查询用户分层数据
export function getStatistics(parameter) {
  return fetch(BASE_URL, userApi.getStatistics, parameter);
}
// 首页查询班主任业绩数据接口
export function getPerformanceTeacher(parameter) {
  return fetch(BASE_URL, userApi.getPerformanceTeacher, parameter);
}
// 获取老师课表日历接口
export function queryTeacherCalendar(parameter) {
  return post(BASE_URL, userApi.queryTeacherCalendar, parameter);
}
// 获取老师的回放课视频
export function getTeacherSchedule(parameter) {
  return post(BASE_URL, userApi.getTeacherSchedule, parameter);
}
// 查询指定科目的课程
export function getSubjectCourse(parameter) {
  return post(BASE_URL, userApi.getSubjectCourse, parameter);
}
// 查询指定班级
export function querySpecifiedClass(parameter) {
  return fetch(BASE_URL, userApi.querySpecifiedClass, parameter);
}
// 创建班级
export function createdSpecifiedClass(parameter) {
  return post(BASE_URL, userApi.createdSpecifiedClass, parameter);
}
// 查询学生课节
export function getCourseScheduleList(parameter) {
  return post(BASE_URL, `${userApi.getCourseScheduleList}${parameter.pageNum ? parameter.pageNum : 1}`, parameter);
}
// 查询学生课时
export function queryStudentClassHourNew(parameter) {
  return fetch(BASE_URL, userApi.queryStudentClassHourNew, parameter);
}
// 课时转移提交审批接口
export function submitCourseSchedule(parameter) {
  return post(BASE_URL, userApi.submitCourseSchedule, parameter);
}
// 获取课时数量
export function queryClassHourNum(parameter) {
  return fetch(BASE_URL, userApi.queryClassHourNum, parameter);
}
// 获取课时审批
export function queryClassHourAdjust(parameter) {
  return fetch(BASE_URL, userApi.queryClassHourAdjust, parameter);
}
// 课时审批结果
export function submitClassHourAdjust(parameter) {
  return post(BASE_URL, userApi.submitClassHourAdjust, parameter);
}
// 查询退款订单课时信息接口
export function queryRefundClassHour(parameter) {
  return fetch(BASE_URL, userApi.queryRefundClassHour, parameter);
}
// 确认退款信息
export function submitRefundClassHour(parameter) {
  return post(BASE_URL, userApi.submitRefundClassHour, parameter);
}
// 确认完成流失记录
export function finishLoseRefund(parameter) {
  return post(BASE_URL, userApi.finishLoseRefund, parameter);
}
// 查询课时流水
export function queryClassFlow(parameter) {
  return fetch(BASE_URL, userApi.queryClassFlow, parameter);
}
// 查询课时变更原因
export function queryChangeReason(parameter) {
  return fetch(BASE_URL, userApi.queryChangeReason, parameter);
}
// 查询作业列表
export function queryHomeWorkList(parameter) {
  return fetch(BASE_URL, userApi.queryHomeWorkList, parameter);
}
// 查询学习里面的学习报告
export function queryStudyReport(parameter) {
  return fetch(BASE_URL, userApi.queryStudyReport, parameter);
}
// 查询统计里面续费数据
export function OrganizationData(parameter) {
  return post(BASE_URL, userApi.OrganizationData, parameter);
}
// 查询统计里面我的组织数据
export function OrganizationTableData(parameter) {
  return post(BASE_URL, userApi.OrganizationTableData, parameter);
}
// 查询统计里面同级组织数据
export function peerTableData(parameter) {
  return post(BASE_URL, userApi.peerTableData, parameter);
}
// 查询统计里面成员组织数据
export function memberTableData(parameter) {
  return post(BASE_URL, userApi.memberTableData, parameter);
}
// 分享赠课入学是按校验
export function enrollmentTime(parameter) {
  return fetch(BASE_URL, userApi.enrollmentTime, parameter);
}
// 获取详情里面作业完成率，学生出勤率
export function profileStatistics(parameter) {
  return fetch(BASE_URL, userApi.profileStatistics, parameter);
}
// 查询学生详情里面的学生作业列表
export function queryStudentHomeWorkList(parameter) {
  return fetch(BASE_URL, userApi.queryStudentHomeWorkList, parameter);
}
// 查询学生详情里面已扣课时详情
export function queryDeductionClass(parameter) {
  return fetch(BASE_URL, userApi.queryDeductionClass, parameter);
}
// 查询学生详情里面补偿课时详情
export function queryCompensateClass(parameter) {
  return fetch(BASE_URL, userApi.queryCompensateClass, parameter);
}
// 学员状态变更
export function submitPayStatus(parameter) {
  return post(BASE_URL, userApi.submitPayStatus, parameter);
}

// 学员状态审批列表
export function queryStudentStatusApproval(parameter) {
  return post(BASE_URL, userApi.queryStudentStatusApproval, parameter);
}
// 学员状态审批是否通过
export function submitStatusApproval(parameter) {
  return post(BASE_URL, userApi.submitStatusApproval, parameter);
}
// 获取订单里面的创建人列表
export function queryCreatorList(parameter) {
  return post(BASE_URL, userApi.queryCreatorList, parameter);
}
// 获取主页订单列表
export function queryOrderList(parameter) {
  return post(BASE_URL, userApi.queryOrderList, parameter);
}
// 判断学员激活是否需要提醒
export function studentReminderActive(parameter) {
  return fetch(BASE_URL, userApi.studentReminderActive, parameter);
}
// 课时审批撤销功能
export function cancelApprove(parameter) {
  return post(BASE_URL, `/api/task/cancel/${parameter.taskId}/${parameter.processInstanceId}`);
}
// 查询新建群发班主任送达人数
export function querySendNum(parameter) {
  return post(BASE_URL, userApi.querySendNum, parameter);
}
// 查询作业列表新建群发班主任送达人数
export function queryHomeWorkSendNum(parameter) {
  return post(BASE_URL, userApi.queryHomeWorkSendNum, parameter);
}
// 新建群发，上传图片到探马

export function uploadGroupFile(parameter) {
  return imgpost(`${BASE_URL}${userApi.uploadGroupFile}`, parameter);
}
// 新建群发，发送
export function sendGroupApi(parameter, isNew) {
  const url = isNew ? userApi.sendGroupApiNew : userApi.sendGroupApi;

  return post(BASE_URL, url, parameter);
}
// 查询班级名称
export function queryClassNameNew(parameter) {
  return fetch(BASE_URL, userApi.queryClassNameNew, parameter);
}
// 匹配代课，查询里面是否有24小时内的课节
export function queryClassHour(parameter) {
  return post(BASE_URL, userApi.queryClassHour, parameter);
}
// 匹配授课老师
export function queryMathTeacher(parameter) {
  return fetch(BASE_URL, userApi.queryMathTeacher, parameter);
}
// 更换授课老师
export function updateClassTeacher(parameter) {
  return post(BASE_URL, userApi.updateClassTeacher, parameter);
}
// 创建代课单
export function createClassOrder(parameter) {
  return post(BASE_URL, userApi.createClassOrder, parameter);
}
// 查询分享赠课记录
export function queryShareLessons(parameter) {
  return post(BASE_URL, userApi.queryShareLessons, parameter);
}
// 审批管理里面的订单审批

export function queryStudentOrderStatus(parameter) {
  return post(BASE_URL, userApi.queryStudentOrderStatus, parameter);
}

// 获取审批管理数据
export function queryStatisticsNum(parameter) {
  return fetch(BASE_URL, userApi.queryStatisticsNum, parameter);
}
// 更改学生昵称
export function modifyName(parameter) {
  return post(BASE_URL, userApi.modifyName, parameter);
}

// 发单管理列表
export function issuingManagement(parameter) {
  return post(BASE_URL, userApi.issuingManagement, parameter);
}
// 取消、排课代课单

export function cancelIssuing(parameter) {
  return fetch(BASE_URL, userApi.cancelIssuing, parameter);
}
// 获取汇率接口
export function exchangeRate(parameter) {
  return post(BASE_URL, userApi.exchangeRate, parameter);
}
// 获取实时汇率接口
export function exchangeRateNew(parameter, orderId) {
  return post(BASE_URL, `/api/student/order/rate/list/${orderId}`, parameter);
}
// 获取学情总结
export function queryConclusion(parameter) {
  return fetch(BASE_URL, `${userApi.queryConclusion}/${parameter.studentId}/${parameter.subject}`);
}

// 获取课程类型
export function queryCourseType(parameter) {
  return fetch(BASE_URL, `${userApi.queryCourseType}/${parameter.subject}`);
}
// 获取课程名称
export function queryCourseName(parameter) {
  return post(BASE_URL, userApi.queryCourseName, parameter);
}

// 获取课程级别下拉列表
export function queryCourseLevelList(parameter) {
  return fetch(BASE_URL, `${userApi.queryCourseLevelList}/${parameter.parentId}`);
}
// 获取课节标签数据
export function queryPerformanceData(studentId, parameter) {
  return post(BASE_URL, `${userApi.queryPerformanceData}/${studentId}`, parameter);
}
// 获取专题课程下拉列表
export function querySchedulingList(parameter) {
  return post(BASE_URL, userApi.querySchedulingList, parameter);
}

// 提交学习规划
export function createStudyPlan(parameter) {
  return post(BASE_URL, userApi.createStudyPlan, parameter);
}
// 获取学习路径下拉列表
export function queryStudyPath(parameter) {
  return fetch(BASE_URL, `${userApi.queryStudyPath}/${parameter.parentId}`);
}
// 获取课程下的课节数量
export function querySectionNum(parameter) {
  return post(BASE_URL, userApi.querySectionNum, parameter);
}
// 获取
export function queryPlanHistory(pageNum, parameter) {
  return post(BASE_URL, `${userApi.queryPlanHistory}/${pageNum}`, parameter);
}
// 修改用户的承接状态
export function changeUnderTake(parameter) {
  return post(BASE_URL, '/api/account/undertake/change', parameter);
}
// 查询班主任每月限额
export function queryMonthLimit(pageNum, parameter) {
  return post(BASE_URL, `/api/class_admin/month_limit/search/${pageNum}`, parameter);
}
// 下载Receipt
export function downReceipt(parameter) {
  return fetch(BASE_URL, `/api/ecommerce/order/${parameter.orderId}/receipt`);
}
// 通过学号查询学生ID
export function queryStudentId(parameter) {
  return fetch(BASE_URL, `/api/student/find_id/${parameter.code}`);
}

// 查询课时有效期左侧课时总览
export function queryCourseUnit(parameter) {
  return fetch(BASE_URL, `/api/student/course_unit/expire/${parameter.studentId}/${parameter.subject}`);
}
// 查询课时明细列表
export function queryCourseUnitDetails(parameter) {
  return fetch(
    BASE_URL,
    `/api/student/course_unit/expire/details/${parameter.studentId}/${parameter.subject}/${parameter.pageNum}`,
  );
}
// 根据订单编号查询订单详情
export function getOrderNumber(parameter) {
  return fetch(BASE_URL, `/api/student/order/number/${parameter.orderNumber}`);
}
// 添加家庭联系方式
export function addFamilyContact(parameter) {
  return post(BASE_URL, '/api/student/family/add/contact', parameter);
}
// 修改家庭联系方式
export function updateFamilyContact(parameter) {
  return post(BASE_URL, '/api/student/family/update/contact', parameter);
}
// 删除家庭联系方式
export function deleteFamilyContact(parameter) {
  return post(BASE_URL, '/api/student/family/delete/contact', parameter);
}
// 设置
export function setFamilyContact(parameter) {
  return post(BASE_URL, '/api/student/family/asDefault/contact', parameter);
}
// 查看手机邮箱记录日志
export function lookFamilyContactLog(parameter) {
  return post(BASE_URL, '/api/student/family/look/contact', parameter);
}
// 申请延期
export function applyClassHourDelay(parameter) {
  return post(BASE_URL, '/api/student/course_unit/apply/delay', parameter);
}
// 查看延期时间
export function lookClassHourDelay(parameter) {
  return fetch(BASE_URL, '/api/student/course_unit/expire/check', parameter);
}
// 查询学生默认电话,邮箱
export function lookStudentDefaultMobile(parameter) {
  return post(BASE_URL, '/api/student/family/default/contact', parameter);
}
// 查询订单详情学生默认电话
export function lookStudentDetailDefaultMobile(parameter) {
  return post(BASE_URL, '/api/student/order/receiverPhone', parameter);
}
// 查询邮件详情
export function queryEmailDetail(parameter) {
  return post(BASE_URL, '/api/follow_record/detail', parameter);
}
// 修改班主任每月限额
export function updateMonthLimit(parameter) {
  return post(BASE_URL, '/api/class_admin/month_limit/modify', parameter);
}
// 查询学生不续费流失记录
export function queryLossRefundCount(parameter) {
  return post(BASE_URL, '/api/student/loss_refund/count', parameter);
}
// 查询学生分享明细
export function queryShareDetail(parameter) {
  return post(BASE_URL, '/api/account/order_track/student/shard/detail', parameter);
}
// 查看悟空豆明细

export function queryWuKongDouDetail(parameter) {
  return post(BASE_URL, '/api/student/student_wu_kong_bean_detail', parameter);
}

//  同一学员与同家庭其他学员待办任务
export function queryStudentOtherTask(parameter) {
  return fetch(BASE_URL, '/api/task/student/other_run_task', parameter);
}
// 获取新生首通体验课信息
export function queryTrailScheduleDetail(parameter) {
  return fetch(BASE_URL, '/api/task/business_detail/task_first_pass', parameter);
}
export function queryConfirmFistDate(parameter) {
  return fetch(BASE_URL, '/api/task/business_detail/has_confirm_fistDate', parameter);
}

export const querySubscribeInfoApi = (uuid) => {
  return fetch(BASE_URL, `/api/student/${uuid}/subject-info`);
};

export const getSubscribeDetailApi = (params) => {
  return post(BASE_URL, `/api/student/subscription/find-by-order-id`, params);
};

export const getTaskUpcomingCount = (subject = '') => {
  return fetch(BASE_URL, `/api/task/upcoming/num?subject=${subject}`);
};
export const getStudentStatistics = () => {
  return fetch(BASE_URL, `/api/account/serve/student/statistics`);
};
export const getHomeDataReport = (params) => {
  return post(BASE_URL, `/api/account/order_track/statistics/class_admin`, params);
};
export const getImportErrorFiles = (uuid) =>
  axios.get(`${BASE_URL}/api/upload/error/file/${uuid}`, { responseType: 'blob' });

// 上传数据文件
export function uploadData(parameter) {
  return axios.post(`${BASE_URL}${userApi.uploadDataForm}`, parameter);
}

export function completeUploadTaskDetail(params) {
  return post(BASE_URL, `${userApi.completeTaskDetail}/${params.taskId}/${params.processInstanceId}`, params);
}
export const getOrderCuInfo = ({ studentId, orderNum }) => {
  return fetch(BASE_URL, `/api/test/findCuDetailByOrderNum/${studentId}/${orderNum}`);
};

export const getAgeOptions = (params) => {
  return post(BASE_URL, `/api/student/search/birth/year`, params);
};

export const phoneCallLog = (params) => {
  return post(BASE_URL, `/api/crm/track/save/call_log`, params);
};
export const getAboutInfo = (params) => {
  return post(BASE_URL, `/api/admin/student/lost/student/last/course/schedule`, params);
};

// 获取自主选课信息
/**
 * @param {string} studentId
 * @param {Object} params
 * @param {"CHINESE"|"MATH"|"ENGLISH"} params.subject
 * @returns {Promise}
 */
export const getSelfBookingInfo = (studentId, params) => {
  return post(BASE_URL, `/api/student/self-booking/${studentId}/search`, params);
};
/**
 * cognia 进度信息
 */
export const getCagniaProgress = (studentCode) => fetch(BASE_URL, `/public/cognia/query/progress/${studentCode}`);

export const getHandleCourseList = (processInstanceId) =>
  fetch(BASE_URL, `/api/admin/teacher/find/substitute/${processInstanceId}`);

export const queryDaylightReadInfo = (studentScheduleId) => fetch(BASE_URL, `/api/dst/record/${studentScheduleId}`);

export const getNextCourse = (studentId, subject) =>
  fetch(BASE_URL, `/api/student/next_normal_schedule/${studentId}/${subject}`);

export const getStudentHandoverInfo = ({ processInstanceId, pageNum, pageSize }) =>
  fetch(BASE_URL, `/api/handover/detail/${processInstanceId}?pageNum=${pageNum}&pageSize=${pageSize}`);

export const downloadHandoverInfoExcel = (uuid) =>
  axios.get(`${BASE_URL}/api/handover/derive/file/${uuid}`, { responseType: 'blob' });

export const handleGetHandoverProgress = ({ handoverId }) => fetch(BASE_URL, `/api/handover/${handoverId}`);

export const getStudentInfoValidate = (studentId) =>
  fetch(BASE_URL, `/api/handover/validate/student-info/${studentId}`);

export const getNewUserTaskStatus = (studentCode) =>
  fetch(BASE_URL, `/api/student/newUserTaskStatus/${studentCode}`);
